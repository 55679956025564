import {BaseService} from "@/core/services/BaseService";

class TemplateService extends BaseService<any> {
  endpoint = '/template';

  async templateList(type, clientId) {
    return await this.http().get(this.endpoint + "/template-list?type=" + type + "&clientId=" + clientId).then(res => {
      return res.data;
    })
  }
}

export default new TemplateService();