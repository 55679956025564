import {BaseService} from "@/core/services/BaseService";
import {ENDPOINT} from "@/core/config/Constant";

class PaymentService extends BaseService<any> {
  endpoint = ENDPOINT.PAYMENT

  async printCheque(id) {
    return this.http().get(this.endpoint + "/" + id + "/export/cheque", {responseType: "blob"}).then(res => {
      return res
    })
  }

  async createGroup(data) {
    return this.http().post(this.endpoint + "/group", data).then(res => {
      return res.data
    })
  }

  async getGroup(id) {
    return this.http().get(this.endpoint + "/group" + "/" + id).then(res => {
      return res.data
    })
  }

  async updateGroup(id, data) {
    return this.http().put(this.endpoint + "/group" + "/" + id, data).then(res => {
      return res.data
    })
  }

  async deleteGroup(id) {
    return this.http().delete(this.endpoint + "/group" + "/" + id).then(res => {
      return res.data
    })
  }

  async printGroupCheque(id) {
    return this.http().get(this.endpoint + "/group/" + id + "/export/cheque", {responseType: "blob"}).then(res => {
      return res
    })
  }

  async printEOB(id) {
    return this.http().get(this.endpoint + "/group/" + id + "/export/eob", {responseType: "blob"}).then(res => {
      return res
    })
  }

  async list(data) {
    return this.http().post(this.endpoint + "/list", data).then(res => {
      return res.data
    })
  }

  async sanctionsCheck(id) {
    return this.http().get(this.endpoint + "/" + id + "/sanctions-check").then(res => {
      return res.data
    })
  }

  async sanctionsUpdate(id, status) {
    return this.http().patch(this.endpoint + "/" + id + "/sanctions-status/" + status).then(res => {
      return res.data
    })
  }
}

export default new PaymentService();