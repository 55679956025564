import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ReminderService from "@/core/services/ReminderService";

@Module
export class ReminderModule extends VuexModule {
  allPage: any = {total: 0, data: [], totalPages: 0, loading: true}
  pendingPage: any = {total: 0, data: [], totalPages: 0, loading: true}
  completedPage: any = {total: 0, data: [], totalPages: 0, loading: true}
  selectedReminder: any = {show: false, data: {}, loading: false}

  @Action
  [Actions.LOAD_ALL_REMINDER](filter) {
    this.context.commit(Mutations.SET_ALL_REMINDER, {total: 0, data: [], totalPages: 0, loading: true})
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    ReminderService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_ALL_REMINDER, {
        total: res.total,
        data: res.data,
        totalPages: res.totalPage,
        loading: false
      })
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Action
  [Actions.LOAD_PENDING_REMINDER](filter) {
    this.context.commit(Mutations.SET_PENDING_REMINDER, {total: 0, data: [], totalPages: 0, loading: true})
    ReminderService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_PENDING_REMINDER, {
        total: res.total,
        data: res.data,
        totalPages: res.totalPage,
        loading: false
      })
    })
  }

  @Action
  [Actions.LOAD_COMPLETED_REMINDER](filter) {
    this.context.commit(Mutations.SET_COMPLETE_REMINDER, {total: 0, data: [], totalPages: 0, loading: true})
    ReminderService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_COMPLETE_REMINDER, {
        total: res.total,
        data: res.data,
        totalPages: res.totalPage,
        loading: false
      })
    })
  }

  @Action
  [Actions.LOAD_REMINDER](id) {
    this.context.commit(Mutations.SET_REMINDER, {show: true, data: {}, loading: true})
    ReminderService.get(id).then(res => {
      this.context.commit(Mutations.SET_REMINDER, {show: true, data: res, loading: false})
    })
  }

  @Mutation
  [Mutations.SET_REMINDER](data) {
    this.selectedReminder = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_REMINDER](data) {
    this.completedPage = data;
  }

  @Mutation
  [Mutations.SET_PENDING_REMINDER](data) {
    this.pendingPage = data;
  }

  @Mutation
  [Mutations.SET_ALL_REMINDER](data) {
    this.allPage = data;
  }

  @Mutation
  ['REMINDER_COMPLETE'](id) {

  }
}