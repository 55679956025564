enum Actions {
  // action types
  LOAD_REMINDER = "LOAD_REMINDER",
  LOAD_COMPLETED_REMINDER  = "LOAD_COMPLETED_REMINDER",
  LOAD_PENDING_REMINDER = "LOAD_PENDING_REMINDER",
  LOAD_ALL_REMINDER ="LOAD_ALL_REMINDER",
  LOAD_SCANS ="LOAD_SCANS",
  LOAD_CUSTOMER_INBOX = "LOAD_CUSTOMER_INBOX",
  LOAD_CUSTOMER = "LOAD_CUSTOMER",
  LOAD_CUSTOMERS = "LOAD_CUSTOMERS",
  LOAD_PAYMENT_SERVICES = "LOAD_PAYMENT_SERVICES",
  LOAD_SERVICES= "LOAD_SERVICES",
  LOAD_LOG_REPORT_NOTIFICATIONS= "LOAD_LOG_REPORT_NOTIFICATIONS",
  CLAIM_FAST_TRACK = "CLAIM_FAST_TRACK",
  LOAD_LOG_REPORT = "LOAD_LOG_REPORT",
  LOAD_LOG_REPORTS = "LOAD_LOG_REPORTS",
  RECEIVE_CHAT_MESSAGE = "RECEIVE_CHAT_MESSAGE",
  LOAD_BOX_MESSAGE = "LOAD_BOX_MESSAGE",
  SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE",
  LOAD_SET_TO_ID= "LOAD_SET_TO_ID",
  LOAD_PROCESS = "LOAD_PROCESS",
  LOAD_COMPLETED_PROCESS = "LOAD_COMPLETED_PROCESS",
  LOAD_ACTIVE_PROCESS = "LOAD_ACTIVE_PROCESS",
  LOAD_ALL_PROCESS = "LOAD_ALL_PROCESS",
  LOAD_TAGS = "LOAD_TAGS",
  LOAD_CHAT_CONTACTS = "LOAD_CHAT_CONTACTS",
  LOAD_TASK= "LOAD_TASK",
  LOAD_COMPLETED_TASK="LOAD_COMPLETED_TASK",
  LOAD_PENDING_TASKS = "LOAD_PENDING_TASKS",
  LOAD_ALL_TASKS = "LOAD_ALL_TASKS",
  LOAD_GROUP ="LOAD_GROUP",
  LOAD_GROUPS ="LOAD_GROUPS",
  LOAD_PRODUCT_BENEFITS = "LOAD_PRODUCT_BENEFITS",
  ACTIVITY_LATEST_REVIEWS= "ACTIVITY_LATEST_REVIEWS",
  LOAD_LOOKUPS = "LOAD_LOOKUPS",
  LOAD_ACTION_VIEWS ="LOAD_ACTION_VIEWS",
  LOAD_ACTION_VIEWS_PANEL ="LOAD_ACTION_VIEWS_PANEL",
  LOAD_FILE_SHARES = "LOAD_FILE_SHARES",
  LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS",
  LOAD_POLICY_INTEGRATION_SEARCH ="LOAD_POLICY_INTEGRATION_SEARCH",
  LOAD_HOSPITALIZATIONS = "LOAD_HOSPITALIZATIONS",
  LOAD_CHART_CREATED_PAYMENT = "LOAD_CHART_CREATED_PAYMENT",
  LOAD_ACTION_TEMPLATES = 'LOAD_ACTION_TEMPLATES',
  LOAD_CHECK_LISTS ="LOAD_CHECK_LISTS",
  ACTIVITY_SEARCH_PAGE= 'ACTIVITY_SEARCH_PAGE',
  LOAD_QUICKSIGHTS_MENU = "LOAD_QUICKSIGHTS_MENU",
  LOAD_QUICKSIGHTS = 'LOAD_QUICKSIGHTS',
  LOAD_FILES = 'LOAD_FILES',
  LOAD_SECTIONS = 'LOAD_SECTIONS',
  LOAD_CONTENT_MAPPINGS = 'LOAD_CONTENT_MAPPINGS',
  SET_RECENT_PAYMENT = 'SET_RECENT_PAYMENT',
  LOAD_PAGES = 'LOAD_PAGES',
  LOAD_PAGE = 'LOAD_PAGE',
  SET_MENU_ACTIVE = 'SET_MENU_ACTIVE',
  LOAD_PAGE_MENU = 'LOAD_PAGE_MENU',
  LOAD_CASE_CLAIMS = 'LOAD_CASE_CLAIMS',
  LOAD_DASHBOARD_NEW_FEED = 'LOAD_DASHBOARD_NEW_FEED',
  LOAD_CHART_CREATED_CLAIM = 'LOAD_CHART_CREATED_CLAIM',
  LOAD_CHART_CREATED_INVOICE = 'LOAD_CHART_CREATED_INVOICE',
  LOAD_CHART_CREATED_CASE = 'LOAD_CHART_CREATED_CASE',
  LOAD_LOGIN_AUDITS = 'LOAD_LOGIN_AUDITS',
  LOAD_ROLES = 'LOAD_ROLES',
  LOAD_USER_ROLES = 'LOAD_USER_ROLES',
  LOAD_USER = 'LOAD_USER',
  LOAD_LOGIN_HISTORY = 'LOAD_LOGIN_HISTORY',
  LOAD_COMPANY_TEMPLATES = 'LOAD_COMPANY_TEMPLATES',
  LOAD_TEMPLATE = 'LOAD_TEMPLATE',
  LOAD_TEMPLATES = 'LOAD_TEMPLATES',
  LOAD_COMPANY = 'LOAD_COMPANY',
  LOAD_CURRENCIES = 'LOAD_CURRENCIES',
  LOAD_EXCHANGE_RATES = 'LOAD_EXCHANGE_RATES',
  LOAD_RESERVE_TIMELINES = 'LOAD_RESERVE_TIMELINES',
  LOAD_TEMPLATES_PHONES = 'LOAD_TEMPLATES_PHONES',
  LOAD_TEMPLATES_EMAILS = 'LOAD_TEMPLATES_EMAILS',
  LOAD_TEMPLATES_DOCUMENTS = 'LOAD_TEMPLATES_DOCUMENTS',
  LOAD_TEMPLATES_NOTES = 'LOAD_TEMPLATES_NOTES',
  LOAD_ACTIVE_PROVIDERS = 'LOAD_ACTIVE_PROVIDERS',
  LOAD_COMPLETED_PROVIDERS = 'LOAD_COMPLETED_PROVIDERS',
  LOAD_POLICY_CLOSED_CLAIMS = 'LOAD_POLICY_CLOSED_CLAIMS',
  LOAD_POLICY_ACTIVE_CLAIMS = 'LOAD_POLICY_ACTIVE_CLAIMS',
  LOAD_POLICY_CLOSED_CASES = 'LOAD_POLICY_CLOSED_CASES',
  LOAD_POLICY_ACTIVE_CASES = 'LOAD_POLICY_ACTIVE_CASES',
  LOAD_LIST_INVOICES = 'LOAD_LIST_INVOICES',
  LOAD_TIMELINES = 'LOAD_TIMELINES',
  LOAD_LIST_CLAIMS = 'LOAD_LIST_CLAIMS',
  LOAD_MY_CASES = 'LOAD_MY_CASES',
  LOAD_WATCHING_CASES = 'LOAD_WATCHING_CASES',
  LOAD_WATCHING_POLICIES = 'loadWatchingPolicies',
  LOAD_WATCHING_ENTITY = 'loadWatchingEntity',
  LOAD_WATCH_CASE = 'loadWatchCase',
  LOAD_ALL_DOCUMENTS = 'loadAllDocuments',
  LOAD_NOTES = 'loadNotes',
  LOAD_WARNING_NOTES = 'LOAD_WARNING_NOTES',
  LOAD_RECEIVED_DOCUMENTS = 'loadReceivedDocuments',
  LOAD_SENT_DOCUMENTS = 'loadSentDocuments',
  LOAD_PENDING_DOCUMENTS = 'loadPendingDocuments',
  LOAD_DOCUMENT_URL = 'loadDocumentUrl',
  LOAD_AUDITS = 'loadAudits',
  SET_RECENT_INVOICE = 'setRecentInvoice',
  LOAD_INVOICE = 'loadInvoice',
  INVOICE_SEARCH = 'invoiceSearch',
  LOAD_CLAIM_INVOICES = 'loadClaimInvoices',
  LOAD_CLAIM_INVOICES_FINALIZED = 'LOAD_CLAIM_INVOICES_FINALIZED',
  LOAD_CLAIM_INVOICES_ALL  = 'LOAD_CLAIM_INVOICES_ALL',
  LOAD_PROVIDER = 'loadProvider',
  LOAD_PROVIDERS = 'loadProviders',
  LOOKUP_SEARCH = 'lookupSearch',
  PROVIDER_LIVE_SEARCH = 'providerLiveSearch',
  LOAD_COMPLETE_ACTIVITIES = 'loadCompleteActivities',
  LOAD_ALL_ACTIVITIES = 'loadAllActivities',
  LOAD_PENDING_ACTIVITIES = 'loadPendingActivities',
  SET_RECENT_CLAIMS = 'setRecentClaim',
  LOAD_CLAIM = 'loadClaim',
  LOAD_CLAIM_PANEL = 'LOAD_CLAIM_PANEL',
  CLAIM_SEARCH = 'claimSearch',
  LOAD_POLICY_CLAIMS = 'loadPolicyClaim',
  LOAD_CONTACTS = 'loadContacts',
  LOAD_ACTIVITY_DOCUMENTS = 'loadActivityDocument',
  LOAD_DOCUMENT = "loadDocument",
  LOAD_DOCUMENTS = "loadDocuments",
  CASE_ACTIVITIES = 'caseActivities',
  CREATE_FORM_ERROR = 'createFormError',
  LOAD_ACTIVITY = 'loadActivity',
  LOAD_ACTIVITY_DETAIL = 'LOAD_ACTIVITY_DETAIL',
  ACTIVITY_SEARCH = 'activitySearch',
  LOAD_COMPANIES = 'loadCompany',
  LOAD_CLIENTS = 'loadClient',
  LOAD_ALL_CLIENT = 'LOAD_ALL_CLIENT',
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  VERIFY_USER = 'verifyUser',
  LOGOUT = "logout",
  REGISTER = "register",
  LOAD_PROFILE = "loadProfile",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_BREADCRUMB_ACTION_LINK = "setBreadcrumbActionLink",
  LOAD_COUNTRY = 'loadCountry',
  LOAD_STATE = 'loadState',
  LOAD_USERS = 'loadUsers',
  CREATE_USER = 'createUsers',
  UPDATE_USER = "updateUser",
  LOAD_PRODUCTS = 'loadProducts',
  LOAD_PRODUCT = 'loadProduct',
  LOAD_PERMISSIONS = 'loadPermission',
  POLICY_SEARCH = 'policySearch',
  LOAD_POLICY = 'loadPolicy',
  LOAD_POLICY_CASES = 'loadPolicyCases',
  CASE_SEARCH = 'caseSearch',
  LOAD_CASE = 'loadCase',
  EDIT_CASE = 'editCase',
  SET_RECENT_POLICY = 'setRecentPolicy',
  SET_RECENT_CASE = 'setRecentCase',
  PHONE_CALL_SEARCH = 'phoneCallSearch',
  LOAD_PAYMENTS = 'LOAD_PAYMENTS',
  SEARCH_PAYMENT = 'SEARCH_PAYMENT',
}

enum Mutations {
  // mutation types
  SET_REMINDER ="SET_REMINDER",
  SET_COMPLETE_REMINDER ="SET_COMPLETE_REMINDER",
  SET_PENDING_REMINDER = "SET_PENDING_REMINDER",
  SET_ALL_REMINDER = "SET_ALL_REMINDER",
  SET_CLAIM_INVOICES_ALL = "SET_CLAIM_INVOICES_ALL",
  SET_CLAIM_INVOICES_FINALIZED = "SET_CLAIM_INVOICES_FINALIZED",
  SET_SCANS ="SET_SCANS",
  SET_CUSTOMER_INBOX = "SET_CUSTOMER_INBOX",
  SET_CUSTOMER= "SET_CUSTOMER",
  SET_CUSTOMERS = "SET_CUSTOMERS",
  SET_PAYMENT_SERVICES = "SET_PAYMENT_SERVICES",
  SET_SERVICES ="SET_SERVICES",
  SET_LOG_REPORT_NOTIFICATIONS = "SET_LOG_REPORT_NOTIFICATIONS" ,
  SET_CLAIM_FAST_TRACK = "SET_CLAIM_FAST_TRACK",
  SET_FILE_NEXT_DATE ="SET_FILE_NEXT_DATE",
  SET_FILE_DATE = "SET_FILE_DATE",
  SET_CONTACT_READ = "SET_CONTACT_READ",
  SET_CONTACT_STATUS ="SET_CONTACT_STATUS",
  SET_LOG_REPORTS = "SET_LOG_REPORTS",
  SET_LOG_REPORT = "SET_LOG_REPORT",
  SET_CHAT_MESSAGE = "SET_CHAT_MESSAGE",
  SET_BOX_MESSAGE = "SET_BOX_MESSAGE",
  WS_SEND  ="WS_SEND",
  WS_UN_SUB_TOPIC = "WS_UN_SUB_TOPIC",
  WS_SUB_TOPIC = "WS_SUB_TOPIC",
  SET_TO_ID = "SET_TO_ID",
  SET_PROCESS = "SET_PROCESS",
  SET_COMPLETED_PROCESS = "SET_COMPLETED_PROCESS",
  SET_ACTIVE_PROCESS = "SET_ACTIVE_PROCESS",
  SET_ALL_PROCESS = "SET_ALL_PROCESS",
  ADDED_TAGS ="ADDED_TAGS",
  SET_TAGS = "SET_TAGS",
  SET_CHAT_CONTACTS = "SET_CHAT_CONTACTS",
  WS_CONNECT = "WS_CONNECT",
  SET_CLIENT_TEMPLATE_SELECT = "SET_CLIENT_TEMPLATE_SELECT",
  SET_COMPLETED_TASK = "SET_COMPLETED_TASK",
  SET_PENDING_TASKS = "SET_PENDING_TASKS",
  SET_ALL_TASKS = "SET_ALL_TASKS",
  SET_GROUP = "SET_GROUP",
  SET_GROUPS = "SET_GROUPS",
  SET_PRODUCT_BENEFITS = "SET_PRODUCT_BENEFITS",
  SET_ACTIVITY_LATEST_REVIEWS = "SET_ACTIVITY_LATEST_REVIEWS",
  SET_LOAD_LOOKUPS= "SET_LOAD_LOOKUPS",
  EMIT_DOCUMENT_ACCESS = "EMIT_DOCUMENT_ACCESS",
  EMIT_RESERVE_ACCESS ="EMIT_RESERVE_ACCESS",
  SET_ACTION_VIEWS= "SET_ACTION_VIEWS",
  SET_FILE_SHARES = "SET_FILE_SHARES",
  SET_NOTIFICATIONS = "SET_NOTIFICATIONS" ,
  SET_POLICY_INTEGRATION_SEARCH = 'SET_POLICY_INTEGRATION_SEARCH',
  SET_HOSPITALIZATIONS = "SET_HOSPITALIZATIONS",
  SET_CHART_CREATED_PAYMENT = "SET_CHART_CREATED_PAYMENT",
  SET_ACTION_TEMPLATES = 'SET_ACTION_TEMPLATES',
  SET_CHECK_LISTS ="SET_CHECK_LISTS",
  SET_ACTIVITY_SEARCH_PAGE = 'SET_ACTIVITY_SEARCH_PAGE',
  SET_QUICKSIGHTS_MENU= "SET_QUICKSIGHTS_MENU",
  SET_QUICKSIGHTS = 'SET_QUICKSIGHTS',
  SET_FILES = 'SET_FILES',
  SET_SELECTED_DOC = 'SET_SELECTED_DOC',
  SET_SECTIONS = "SET_SECTIONS",
  SET_CONTENT_MAPPING = 'SET_CONTENT_MAPPING',
  SET_SEARCH_PAYMENT = 'SET_SEARCH_PAYMENT',
  SET_PAGE_ENTITY = 'SET_PAGE_ENTITY',
  SET_PAGES ='SET_PAGES',
  SET_ACTIVE_PAGE ='SET_ACTIVE_PAGE',
  SET_PAGE_ITEMS = 'SET_PAGE_ITEMS',
  SET_HELP_MENU = 'SET_HELP_MENU',
  SET_CASE_CLAIMS = 'SET_CASE_CLAIMS',
  SET_DASHBOARD_NEW_FEED = 'SET_DASHBOARD_NEW_FEED',
  SET_CHART_CREATED_CLAIM = 'SET_CHART_CREATED_CLAIM',
  SET_CHART_CREATED_INVOICE = 'SET_CHART_CREATED_INVOICE',
  SET_CHART_CREATED_CASE = 'SET_CHART_CREATED_CASE',
  SET_LOGIN_AUDITS = 'SET_LOGIN_AUDITS',
  SET_LOGIN_HISTORY = 'SET_LOGIN_HISTORY',
  SET_ROLES = 'SET_ROLES',
  SET_USER_ROLES = 'SET_USER_ROLES',
  SET_COMPANY_TEMPLATES = 'SET_COMPANY_TEMPLATES',
  SET_COMPANY = 'SET_COMPANY',
  SET_WARNING_NOTES = 'SET_WARNING_NOTES',
  SET_RESERVE = 'SET_RESERVE',
  SET_CURRENCIES = 'SET_CURRENCIES',
  SET_EXCHANGE_RATES = 'SET_EXCHANGE_RATES',
  SET_RESERVE_TIMELINES = 'SET_RESERVE_TIMELINES',
  SET_TEMPLATE_NOTES = 'SET_TEMPLATE_NOTES',
  SET_TEMPLATE_PHONES = 'SET_TEMPLATE_PHONES',
  SET_TEMPLATE_EMAILS = 'SET_TEMPLATE_EMAILS',
  SET_TEMPLATE_DOCUMENTS = 'SET_TEMPLATE_DOCUMENTS',
  SET_SEARCH_LOADING = 'SET_SEARCH_LOADING',
  SET_ENTITY_LOADING = 'SET_ENTITY_LOADING',
  SET_ACTIVE_PROVIDERS = 'SET_ACTIVE_PROVIDERS',
  SET_COMPLETED_PROVIDERS = 'SET_COMPLETED_PROVIDERS',
  SET_POLICY_CLOSED_CLAIMS = 'SET_POLICY_CLOSED_CLAIMS',
  SET_POLICY_ACTIVE_CLAIMS = 'SET_POLICY_ACTIVE_CLAIMS',
  SET_POLICY_CLOSED_CASES = 'SET_POLICY_CLOSED_CASES',
  SET_POLICY_ACTIVE_CASES = 'SET_POLICY_ACTIVE_CASES',
  SET_LIST_INVOICES = 'SET_LIST_INVOICES',
  SET_TIMELINES = 'SET_TIMELINES',
  SET_LIST_CLAIMS = 'SET_LIST_CLAIMS',
  SET_WATCHING_CASES = 'SET_WATCHING_CASES',
  SET_WATCHING_POLICIES = 'SET_WATCHING_POLICIES',
  SET_WATCHING_ENTITY = 'SET_WATCHING_ENTITY',
  SET_ALL_DOCUMENTS = 'setAllDocuments',
  SET_ALL_ACTIVITIES = 'setAllActivities',
  SET_NOTES = 'setNotes',
  SET_RECEIVED_DOCUMENTS = 'setReceivedDocuments',
  SET_SENT_DOCUMENTS = 'setSentDocuments',
  SET_PENDING_DOCUMENTS = 'setPendingDocuments',
  SET_DOCUMENT_URL = 'setDocumentUrl',
  SET_AUDITS = 'setAudits',
  SET_INVOICE = 'setInvoice',
  SET_INVOICE_SEARCH = 'setInvoiceSearch',
  SET_CLAIM_INVOICES = 'loadClaimInvoice',
  SET_PROVIDER = 'setProvider',
  SET_PROVIDERS = 'setProviders',
  SET_LOOKUP_SEARCH = 'setLookupSearch',
  SET_PROVIDER_LIVE_SEARCH = 'setProviderLiveSearch',
  SET_COMPLETE_ACTIVITIES = 'setCompleteActivities',
  SET_PENDING_ACTIVITIES = 'setPendingActivities',
  SET_CLAIM = 'setClaim',
  SET_CLAIM_SEARCH = 'setClaimSearch',
  SET_POLICY_CLAIMS = 'setPolicyClaim',
  SET_CONTACTS = 'setContacts',
  SET_ACTIVITY_DOCUMENTS = 'setActivityDocuments',
  SET_DOCUMENT = 'setDocument',
  SET_DOCUMENTS = 'setDocuments',
  EMIT_ACTIVITY_ACCESS = 'emitActivityAccess',
  SET_FORM_ERROR = 'setFormError',
  SET_ACTIVITY = 'setActivity',
  SET_COMPANIES = 'setCompany',
  SET_CLIENTS = 'setClient',
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_VERIFY_CODE = 'setVerifyCode',
  SET_AUTH = "setAuth",
  SET_PROFILE = "setProfile",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_BREADCRUMB_MUTATION_LINK = "setBreadcrumbMutationLink",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_LOCATION_COUNTRY = 'setLocationCountry',

  SET_LOCATION_STATE = 'setLocationState',
  // users
  SET_USERS = 'setUsers',
  SET_USER = 'setUser',
  //product
  SET_PRODUCTS = 'setProducts',
  SET_PRODUCT = 'setProduct',
  SET_PERMISSIONS = 'setPermissions',
  SET_POLICY_SEARCH = 'setPolicySearch',
  SET_POLICY = 'setPolicy',
  SET_POLICY_CASES = 'setPolicyCases',
  SET_CASE_SEARCH = 'setCaseSearch',
  SET_CASE = 'setCase',
  SET_EDIT_CASE = 'setEditCase',
  SET_ACTIVITY_SEARCH = 'setActivitySearch',
  SET_ACTIVITIES = 'setActivities',
  SET_PAYMENTS = 'SET_PAYMENTS',
}

export { Actions, Mutations };
