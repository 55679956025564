import {BaseService} from "@/core/services/BaseService";

class ProcessService extends BaseService<any> {
  endpoint = "/process";

  async overview(type) {
    return this.http().get(this.endpoint + "/overview/" + type).then(res => {
      return res.data;
    })
  }

  async createdChart(type) {
    return this.http().get(this.endpoint + "/chart/created/" + type).then(res => {
      return res.data;
    })
  }

  async completeChart(type) {
    return this.http().get(this.endpoint + "/chart/completed/" + type).then(res => {
      return res.data;
    })
  }
}

export default new ProcessService();