import type {IFilter} from "@/core/entity/IFilter";
import {ISearchFilter} from "@/core/entity/ISearchFilter";

export function generatorQueryString(term: IFilter): string {
  let queryStr = '';
  if (term) {
    Object.keys(term).forEach(key => {
      if (term[key]) {
        if (key === 'paginationPage') {
          queryStr += `&offset=${(term.paginationPage - 1) * term.paginationSize}`;
        } else if (key === 'paginationSize') {
          queryStr += `&max=${term[key]}`;
        } else if (key === 'key') {
          const temp = term[key];
          Object.keys(temp).forEach(k => {
            queryStr += `&${k}=${temp[k]}`;
          });
        }
      }
    });
  }
  return queryStr;
}


export function generateSearchString(filter: ISearchFilter) {
  let queryStr = '';
  if (filter.query) {
    let e = "&q="
    for (const q of filter.query) {
      const value = q.value !== undefined ? q.value.toString().trim() : ''
      e += q.column + ":" + q.operator + ":" + value + "!"
    }
    queryStr += e;
  }
  if (filter.entity) {
    queryStr += "&e=" + filter.entity;
  }
  if (filter.fields) {
    queryStr += "&f=" + filter.fields.join(",")
  }

  if (filter.expands) {
    queryStr += "&ep=" + filter.expands.join(",")
  }

  queryStr += `&p=offset:${(filter.paginationPage - 1) * filter.paginationSize}!size:${filter.paginationSize}!sort:dateCreated:desc`
  return queryStr;
}

export function generateString(filter: any) {
  if (filter == undefined) return '';
  let queryStr = '';
  Object.keys(filter).forEach(key => {
    queryStr += `&${key}=${filter[key]}`;
  });
  return queryStr;
}


export function buildFormData(data: any) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    if (data[key]) formData.set(key, data[key])
  });
  return formData;
}

export function exchangeRate(rate: number, amount: number, isReduce: boolean) {
  if (!isReduce) return rate * amount;
  const revertRate = 1 / rate;
  return amount * revertRate;
}


export function makeDownloadLink(data) {
  const fileName = data.headers["content-disposition"].split("filename=")[1];
  const blob = new Blob([data.data], {type: data.headers['content-type']});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function activeMenu(menu, item) {
  menu.forEach(a => {
    if (menu.includes(item))
      a.active = false;
    if (!a.children) return;
    a.children.forEach(b => {
      if (a.children.includes(item)) {
        b.active = false
      }
      if (!b.children) return false;
      b.children.forEach(c => {
        if (b.children.includes(item)) {
          c.active = false
        }
      })
    })
  });
}


export function buildTerm(filter: any) {
  let terms = '';
  if (filter.npi) {
    terms += '+' + filter.npi;
  }
  if (filter.name) {
    terms += '+' + filter.name;
  }
  if (filter.line1) {
    terms += '+' + filter.line1;
  }
  if (filter.city) {
    terms += '+' + filter.city;
  }
  if (filter.country) {
    terms += '+' + filter.country;
  }
  return terms;
}
